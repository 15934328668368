import React from "react";
import Head from "next/head";
import Link from "next/link";
import dynamic from "next/dynamic";
import LazyLoad from "react-lazyload";
import LandingHeader from "../components/LandingHeader/LandingHeader";
import ImageSlider from "../components/ImageSlider/ImageSlider";
import Videos from "../components/Videos";
import styles from "../styles/Landing.module.css";
const TestimonialSlider = dynamic(() =>
  import("../components/TestimonialSlider")
);
const Footer = dynamic(() => import("../components/Footer"));
const FeaturedIn = dynamic(() => import("../components/FeaturedIn"));
const PartnerLogo = dynamic(() => import("../components/PartnerLogo"));
const Awards = dynamic(() => import("../components/Awards"));

import LandingBrandSlider from "../components/LandingBrandSlider";
import WhyBudli from "../components/WhyBudli";
import LandingFAQ from "../components/LandingFAQ";
import SEOfaq from "../components/SEOfaq";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { fetchPosts } from "../lib/api";
import fetch from "node-fetch";
const s3_front_end_image =
  "https://budlisell.s3.ap-south-1.amazonaws.com/assets/themes/forntend/default/img/";
const getShareCount = (postId) => {
  // Replace this with actual logic to fetch share count
  // For now, returning a placeholder value
  return 123;
};
import Image from 'next/image';
function Home({ testimonials, dealOfDay, deviceType, posts, error }) {
  if (error) {
    return <p>Error loading posts: {error}</p>;
  }

  const breakpoints = {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 3,
    },
  };
  const sellLinks = [
    {
      image: "/images/mobile_icon.jpg",
      link: "/sell-used-mobile-phones",
      caption: "Sell Mobile",
    },
    {
      image: "/images/tablet_icon.jpg",
      link: "/sell-used-tablets",
      caption: "Sell Tablet",
    },
    {
      image: "/images/laptop_icon.jpg",
      link: "/sell-used-laptops",
      caption: "Sell Laptop",
    },
    {
      image: "/images/other_gadget_icon.jpg",
      link: "/sell-used-othergadgets",
      caption: "Sell Other Gadgets",
    },
  ];
  const buyLinks = [
    {
      image: "/images/mobile_icon.jpg",
      link: "https://buy.budli.in/collections/smartphones",
      caption: "Buy Mobile",
    },
    {
      image: "/images/tablet_icon.jpg",
      link: "https://buy.budli.in/collections/tablets",
      caption: "Buy Tablet",
    },
    {
      image: "/images/laptop_icon.jpg",
      link: "https://buy.budli.in/collections/laptops",
      caption: "Buy Laptop",
    },
    {
      image: "/images/other_gadget_icon.jpg",
      link: "https://buy.budli.in/collections/other-gadgets",
      caption: "Buy Other Gadgets",
    },
  ];

  return (
    <>
      <Head>
        <title>
        Buy & Sell Old or Refurbished Mobile Phones, Laptops Online at Best Price
        </title>
        <meta
          name="description"
          content="Buy refurbished or renewed mobile phones, tablets, laptops, and smartwatches, or sell your old devices at unbeatable prices. Great deals await you!
"
        />
        <meta
          name="Keywords"
          content="sell second hand mobile phone, sell mobile, sell old mobile, used laptop, 
        Online ReCommerce portal, sell mobile online, online ReCommerce portal India, 
        sell used mobile, sell used mobile phone, sell mobile phones, sell used tablet, sell ipod, 
        sell tab, sell used iphone, sell used ipad, sell used laptop, sell macbook, sell old laptop"
        />
        <meta
          name="facebook-domain-verification"
          content="d9phh00wnraca6j627lbozxk1dja2a"
        />
        <link rel="canonical" href="https://budli.in:443/" />
      </Head>

      <div className={`App`}>
        <div className={styles.wrapper}>
          <LandingHeader
            s3_front_end_image={s3_front_end_image}
            deviceType={deviceType}
          />
          <h1 style={{display:'none'}}>Buy Refurbished Gadgets</h1>
          <section className="pb-md-0 pb-4">
            <div className="container px-lg-5">
              <div className="row">
                <div className="col-md-6 border-right  py-md-4 py-3">
                  <h2 className="mb-md-3 mb-2 text-center h4 fw-600">
                    <Link href="/sell">
                      <a className="text-dark">Sell Used Gadgets</a>
                    </Link>
                  </h2>
                  <div className="row mob-row5 sellwidgt">
                    <div className="col-3">
                      <Link href="/sell-used-mobile-phones">
                        <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                          <Image
                            src="/images/sell-mob.webp"
                            width={123}
                            height={82}
                            alt="Sell Mobile"
                            className="w-100 h-auto"
                          />
                          <small>Sell Mobile</small>
                        </a>
                      </Link>
                    </div>
                    <div className="col-3">
                      <Link href="/sell-used-tablets">
                        <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                          <Image
                            src="/images/sell-tab.webp"
                            width={123}
                            height={82}
                            alt="Sell Tablet"
                            className="w-100 h-auto"
                          />
                          <small>Sell Tablet</small>
                        </a>
                      </Link>
                    </div>
                    <div className="col-3">
                      <Link href="/sell-used-laptops">
                        <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                          <Image
                            src="/images/sell-lap.webp"
                            width={123}
                            height={82}
                            alt="Sell Laptop"
                            className="w-100 h-auto"
                          />
                          <small>Sell Laptop</small>
                        </a>
                      </Link>
                    </div>
                    <div className="col-3">
                      <Link href="/sell-used-othergadgets">
                        <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                          <Image
                            src="/images/sell-Gadgets.webp"
                            width={123}
                            height={82}
                            alt="Sell Other Gadgets"
                            className="w-100 h-auto"
                          />
                          <small>Sell Other Gadgets</small>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6  pt-md-4">
                  <h1 className="mb-md-3 mb-2 text-center h4 fw-600">
                    <Link href="https://buy.budli.in/">
                      <a className="text-dark">Buy Refurbished Gadgets</a>
                    </Link>
                  </h1>
                  <div className="row mob-row5 buywidgt">
                    <div className="col-3">
                      <Link href="https://buy.budli.in/collections/smartphones">
                        <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                          <Image
                            src="/images/buy-mob.webp"
                            width={123}
                            height={82}
                            alt="Buy Mobile"
                            className="w-100 h-auto"
                          />
                          <small>Buy Mobile</small>
                        </a>
                      </Link>
                    </div>
                    <div className="col-3">
                      <Link href="https://buy.budli.in/collections/tablets">
                        <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                          <Image
                            src="/images/sell-tab.webp"
                            width={123}
                            height={82}
                            alt="Buy Tablet"
                            className="w-100 h-auto"
                          />
                          <small>Buy Tablet</small>
                        </a>
                      </Link>
                    </div>
                    <div className="col-3">
                      <Link href="https://buy.budli.in/collections/laptops">
                        <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                          <Image
                            src="/images/buy-lap.webp"
                            width={123}
                            height={82}
                            alt="Buy Laptop"
                            className="w-100 h-auto"
                          />
                          <small>Buy Laptop</small>
                        </a>
                      </Link>
                    </div>
                    <div className="col-3">
                      <Link href="https://buy.budli.in/collections/other-gadgets">
                        <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                          <Image
                            src="/images/sell-Gadgets.webp"
                            width={123}
                            height={82}
                            alt="Buy Other Gadgets"
                            className="w-100 h-auto"
                          />
                          <small>Buy Other Gadgets</small>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="landinghero overflow-hidden">
            <ImageSlider theme={"light"} />
          </section>
          <section className="text-center border-bottom infosec fw-600 text-dark">
            <div className="container">
              <div className="row mob-row10">
                <div className="col-3 py-md-3 py-2 border-right">
                  <Image
                    src="/images/info1.svg"
                    width={55}
                    height={55}
                    alt="Best Value"
                  />
                  <div className="d-block mt-1">Best Value</div>
                </div>
                <div className="col-3 py-md-3 py-2 border-right">
                  <Image
                    src="/images/info2.svg"
                    width={55}
                    height={55}
                    alt="Safe & Secure"
                  />
                  <div className="d-block mt-1">Safe & Secure</div>
                </div>
                <div className="col-3 py-md-3 py-2 border-right">
                  <Image
                    src="/images/info3.svg"
                    width={55}
                    height={55}
                    alt="Hassle Free"
                  />
                  <div className="d-block mt-1">Hassle Free</div>
                </div>
                <div className="col-3 py-md-3 py-2">
                  <Image
                    src="/images/info4.svg"
                    width={55}
                    height={55}
                    alt="Eco Friendly"
                  />
                  <div className="d-block mt-1">Eco Friendly</div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-4 testisec">
            <div className="container">
              <div className="position-relative">
                <div className="testititsec text-center text-lg-left w-100">
                  <h2 className="mb-md-3 mb-2 h4 fw-600">
                    <Link href="/user-reviews">
                      <a className="text-dark">What Our Users Say</a>
                    </Link>
                  </h2>
                  <div className="text-dark mb-md-3 mb-2 testisubtit">
                    Join our{" "}
                    <strong className="text-default">
                      600,000+ satisfied customers
                    </strong>
                    . Discover why they trust Budli for their tech needs
                  </div>
                </div>
              </div>
                <TestimonialSlider
                  slideperpage="2"
                  testimonials={testimonials}
                />
            </div>
          </section>
          <section className="py-4 bg-light videosec">
            <div className="container">
              <div className="position-relative">
                <div className="videosecinr text-center text-lg-left w-100">
                  <h2 className="mb-2 h4 fw-600">Media & Customer Insights</h2>
                </div>
              </div>
              <LazyLoad>
                <Videos />
              </LazyLoad>
            </div>
          </section>
          <section className="pt-4 pb-md-2 pb-4">
            <div className="container">
              <h2 className="mb-2 mb-lg-3 h4 fw-600 text-center p-0 col-lg-4 mx-auto">
                Save <span className="text-danger">upto 72%</span> on Renewed
                Mobiles, Laptops and Tablets
              </h2>
              <div className="row row10 collagegrid">
                <div className="col mb-20">
                  <Link href={"https://buy.budli.in/collections/mobile-phones"}>
                    <a className="d-block">
                      {deviceType === "mobile" ? (
                        <Image
                          src="/images/saveupto1-mob.webp"
                          width={535}
                          height={655}
                          alt=""
                          className="w-100 h-auto"
                        />
                      ) : (
                        <Image
                          src="/images/saveupto1.webp"
                          width={437}
                          height={535}
                          alt=""
                          className="w-100 h-auto"
                        />
                      )}
                    </a>
                  </Link>
                </div>
                <div className="col mb-20">
                  <Link href={"https://buy.budli.in/collections/laptops"}>
                    <a className="d-block">
                      {deviceType === "mobile" ? (
                        <Image
                          src="/images/saveupto2-mob.webp"
                          width={535}
                          height={924}
                          alt=""
                          className="w-100 h-auto"
                        />
                      ) : (
                        <Image
                          src="/images/saveupto2.webp"
                          width={309}
                          height={535}
                          alt=""
                          className="w-100 h-auto"
                        />
                      )}
                    </a>
                  </Link>
                </div>
                <div className="col">
                  <Link href={"https://buy.budli.in/collections/tablets"}>
                    <a className="d-block mb-20">
                      {deviceType === "mobile" ? (
                        <Image
                          src="/images/saveupto3-mob.webp"
                          width={535}
                          height={271}
                          alt=""
                          className="w-100 h-auto"
                        />
                      ) : (
                        <Image
                          src="/images/saveupto3.webp"
                          width={504}
                          height={255}
                          alt=""
                          className="w-100 h-auto"
                        />
                      )}
                    </a>
                  </Link>
                  <Link href={"https://buy.budli.in/collections/smartwatches"}>
                    <a className="d-block">
                      {deviceType === "mobile" ? (
                        <Image
                          src="/images/saveupto4-mob.webp"
                          width={535}
                          height={276}
                          alt=""
                          className="w-100 h-auto"
                        />
                      ) : (
                        <Image
                          src="/images/saveupto4.webp"
                          width={504}
                          height={255}
                          alt=""
                          className="w-100 h-auto"
                        />
                      )}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="py-4 bg-default-red videosec">
            <div className="container">
              <div className="position-relative">
                <div className="videosecinr">
                  <h2 className="mb-2 h4 fw-600 text-white">
                    Brands We Deal In
                  </h2>
                </div>
              </div>
              <LandingBrandSlider />
            </div>
          </section>
          <section className="pt-4 pb-md-2 pb-4 whybudly">
            <div className="container">
              <div className="text-center mb-3">
                <h2 className="mb-1 h4 fw-600">
                  <Link href="/why-budli">
                    <a className="text-dark">Why Budli</a>
                  </Link>
                </h2>
                <div>Get the best deal while buying or selling</div>
              </div>
              <WhyBudli />
            </div>
          </section>
          <section className="pb-lg-5 pb-4">
            <div className="container">
              <h2 className="mb-3 h4 fw-600 text-center">
                In The Spotlight: See Where We've Been Featured
              </h2>
                <FeaturedIn data={{ s3_url: s3_front_end_image }} />
            </div>
          </section>
          <section className="pb-lg-5 pb-4">
            <div className="container">
              <h2 className="mb-3 h4 fw-600 text-center col-lg-5 p-0 mx-auto">
                Recognition: Awards we've received for our service and
                commitment
              </h2>
                <Awards data={{ s3_url: s3_front_end_image }} />
            </div>
          </section>
          <section className="py-4 bg-light">
            <div className="container">
              <h2 className="mb-3 h4 fw-600">
                <Link href="/faq">
                  <a className="text-dark">Frequently Asked Questions</a>
                </Link>
              </h2>
              <LandingFAQ />
            </div>
          </section>
          <section className="py-4 featuredblogsec">
            <div className="container">
              <div className="text-center mb-md-3 mb-2 p-0 col-lg-8 mx-auto">
                <h2 className="mb-1 h4 fw-600">
                  <Link href="budli.in/blog/">
                    <a className="text-dark">Latest from Budli</a>
                  </Link>
                </h2>
                <div className="text-dark">
                  Explore our blogs and articles for tips, trends, and insights
                  on selling used gadgets, e-waste management, and more.
                </div>
              </div>
              <Swiper
                modules={[Pagination, Autoplay]}
                spaceBetween={20}
                slidesPerView={3}
                autoplay={{ delay: 3000 }}
                pagination={{ clickable: true }}
                breakpoints={breakpoints}
              >
                {posts.map((post) => (
                  <SwiperSlide key={post.id}>
                    <Link href={post.link}>
                      <a className="d-flex flex-column gap-2 blgthmb">
                        <div className="blgimg">
                          {post._embedded?.["wp:featuredmedia"]?.[0]
                            ?.source_url && (
                                <Image
                              src={
                                post._embedded["wp:featuredmedia"][0].source_url
                              }
                              width={417}
                              height={220}
                              alt={
                                post._embedded["wp:featuredmedia"][0]
                                  .alt_text || post.title.rendered
                              }
                              className="d-block w-100 rounded-lg h-auto"
                            />                           
                          )}
                        </div>
                        <h5>{post.title.rendered}</h5>
                      </a>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </section>
          <section className="py-4 border-top">
            <div className="container">
              <SEOfaq />
            </div>
          </section>
          <Footer data={{ s3_url: s3_front_end_image }} />
        </div>
      </div>
    </>
  );
}

// This gets called on every request
export async function getServerSideProps(context) {
  try {
    // Fetching WordPress posts
    const posts = await fetchPosts();

    // Fetching testimonials
    const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}testimonials/allTestimonials/X-API-KEY/${process.env.NEXT_PUBLIC_API_KEY}/type/text/limit/10`;
    const res = await fetch(apiUrl);
    const testimonials = await res.json();

    // Fetching deal of the day
    const dofdApiUrl = `${process.env.NEXT_PUBLIC_API_URL}banners/dealOfTheDay/X-API-KEY/${process.env.NEXT_PUBLIC_API_KEY}`;
    const dealData = await fetch(dofdApiUrl);
    const dealOfDay = await dealData.json();

    // Detecting device type
    const UA = context.req ? context.req.headers["user-agent"] : "";
    const isMobile = UA
      ? Boolean(
          UA.match(/Android|BlackBerry|iPhone|Opera Mini|IEMobile|WPDesktop/i)
        )
      : false;

    // Passing data to the page via props
    return {
      props: {
        testimonials,
        dealOfDay,
        deviceType: isMobile ? "mobile" : "desktop",
        posts,
      },
    };
  } catch (error) {
    return {
      props: {
        error: error.message,
      },
    };
  }
}

export default Home;
