import Link from "next/link";
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import Image from 'next/image';


function ImageSlider({ theme }) {
  /////////////////
  const [screenWidth, setScreenWidth] = useState(0);
  const [isMyMobile, setIsMyMobile] = useState(false);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setIsMyMobile(screenWidth <= 767);
  }, [screenWidth]);
  ///////////////////////////
  return (
    <>
      <LazyLoad>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          navigation
          autoplay={{ delay: 3000 }}
          pagination={{ clickable: true }}
          
          
        >
          
          <SwiperSlide>
            <Link href={"/sell"}>
              <a className="d-block">
              {isMyMobile && (
                <Image
                    src="/images/sell-banner-mobile.webp"
                    width={565}
                    height={395}
                    alt="Sell"
                    className="w-100 h-auto"
                  />
              )}
              {!isMyMobile && (
                <Image
                    src="/images/sell-banner.webp"
                    width={1730}
                    height={433}
                    alt="Sell"
                    className="w-100 h-auto"
                  />
              )}
                </a>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link href={"https://buy.budli.in/collections/mobile-phones"}>
              <a className="d-block">
              {isMyMobile && (
                <Image
                    src="/images/buy-mobile-phones-mobile.webp"
                    width={565}
                    height={395}
                    alt="Second slide"
                    className="w-100 h-auto"
                  />
              )}
              {!isMyMobile && (
                <Image
                    src="/images/buy-mobile-phones-desktop.webp"
                    width={1730}
                    height={433}
                    alt="Second slide"
                    className="w-100 h-auto"
                  />
              )}
                </a>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link href={"https://buy.budli.in/collections/laptops"}>
            <a className="d-block">
              {isMyMobile && (
                <Image
                    src="/images/buy-laptop-mobile.webp"
                    width={565}
                    height={395}
                    alt="Second slide"
                    className="w-100 h-auto"
                  />
              )}
              {!isMyMobile && (
                <Image
                    src="/images/buy-laptop-desktop.webp"
                    width={1730}
                    height={433}
                    alt="3rd slide"
                    className="w-100 h-auto"
                  />
              )}
              </a>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link href={"https://buy.budli.in/collections/tablets"}>
            <a className="d-block">
              {isMyMobile && (
                <Image
                    src="/images/buy-tablets-mobile.webp"
                    width={565}
                    height={395}
                    alt="4th slide"
                    className="w-100 h-auto"
                  />
              )}
              {!isMyMobile && (
                <Image
                    src="/images/buy-tablets-desktop.webp"
                    width={1730}
                    height={433}
                    alt="4th slide"
                    className="w-100 h-auto"
                  />
              )}
              </a>
            </Link>
          </SwiperSlide>
        </Swiper>
      </LazyLoad>
    </>
    
  );
}

export default ImageSlider;
